













import Vue from "vue";

export default Vue.extend({
  name: "NavItems",
  components: {},
  data: () => ({
    items: [
      {
        label: "Formation",
        routerLink: "/formation"
      },
      {
        label: "Expériences",
        routerLink: "/experiences"
      },
      {
        label: "Contact",
        routerLink: "/contact"
      }
    ]
  })
});
