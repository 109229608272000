
















































import Vue from "vue";
import NavItems from "@/components/header/NavItems.vue";

export default Vue.extend({
  name: "App",
  metaInfo: {
    title: "Antoine Steyer"
  },

  components: {
    NavItems
  },

  data: () => ({
    drawer: false
  })
});
