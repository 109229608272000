























































import Vue from "vue";

interface Skill {
  label: string;
  icon?: string;
  percent: number;
  type: "Front" | "Back";
}

export default Vue.extend({
  name: "SkillList",
  data: () => ({
    skillType: null,
    skills: [
      {
        label: "Typescript",
        icon: "mdi-language-typescript",
        percent: 90,
        type: "Front"
      },
      {
        label: "CSS",
        icon: "mdi-language-css3",
        percent: 80,
        type: "Front"
      },
      {
        label: "HTML",
        icon: "mdi-language-html5",
        percent: 90,
        type: "Front"
      },
      {
        label: "VueJS",
        icon: "mdi-vuejs",
        percent: 90,
        type: "Front"
      },
      {
        label: "Angular",
        icon: "mdi-angular",
        percent: 90,
        type: "Front"
      },
      {
        label: "React",
        icon: "mdi-react",
        percent: 40,
        type: "Front"
      },
      {
        label: "React Native",
        icon: "mdi-cellphone",
        percent: 50,
        type: "Front"
      },
      {
        label: "Java",
        icon: "mdi-language-java",
        percent: 60,
        type: "Back"
      },
      {
        label: "Spring",
        icon: "mdi-leaf",
        percent: 50,
        type: "Back"
      },
      {
        label: "Python",
        icon: "mdi-language-python",
        percent: 20,
        type: "Back"
      }
    ] as Skill[]
  }),
  computed: {
    filteredSkills: function() {
      return this.skills.filter(
        skill => skill.type === this.skillType || !this.skillType
      );
    }
  }
});
