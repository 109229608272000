


































































import Vue from "vue";
import SkillList from "@/components/SkillList.vue";

const BIRTHDAY = new Date("1995-03-05");

export default Vue.extend({
  name: "Home",
  components: { SkillList },
  computed: {
    age: function() {
      const today = new Date();
      const birthDate = BIRTHDAY;
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    }
  }
});
